<template>
    <div class="mypage_curation_send_wrap">
        <!--        내 응모 아이템 리스트 레이아웃-->
        <table class="my_history_table" width="100%" border="0" cellspacing="0" cellpadding="0"
               style="table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="95px"/>
                <col width="130px"/>
                <col width="220px"/>
                <col width="130px"/>
                <col width="220px"/>
                <col width="140px"/>
                <col width="140px"/>
                <col width="120px"/>
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{ $t('image') }}</th>
                <th colspan="1">{{ $t('item') }} {{ $t('subject') }}</th>
                <th colspan="1">{{ $t('get_vote') }}</th>
                <th colspan="1">{{ $t('competition') }} {{ $t('subject') }}</th>
                <th colspan="1">{{ $t('status') }}</th>
                <th colspan="1">{{ $t('entry') }}{{ $t('dateTime') }}</th>
                <th colspan="1">{{ $t('entry') }}{{ $t('cancel') }}</th>
            </tr>
            <tr v-for="(competition, index) in competitionData" :key="`competition-my-item-${competition.c_idx}`" class="cursor">
                <td @click="movePage(`/item/${competition.Item.i_sku}`)" class="competition_list">{{ tableIdx(index) }}</td>  <!-- No -->
                <td @click="movePage(`/item/${competition.Item.i_sku}`)" class="competition_list"><img :src="returnImage(competition.Item.ItemFile.Item,78,49)" width="78" height="49"/></td>
                <td @click="movePage(`/item/${competition.Item.i_sku}`)" class="t_subject competition_list">{{ competition.Item.i_name }}</td>  <!-- 아이템 제목 -->

                <td @click="movePage(`/item/${competition.Item.i_sku}`)" >
                    {{ returnCommas(competition.ci_vote_cnt) }}({{ returnPercentage(competition.ci_vote_cnt, competition.totalVote) }}%)
                </td> <!-- 득표 -->

                <td @click="movePage(`/item/${competition.Item.i_sku}`)"  class="t_subject">{{ competition.c_title }}</td>  <!-- 공모전제목 -->


                <td @click="movePage(`/item/${competition.Item.i_sku}`)" >
                    {{ returnStatus(competition.ci_status) }}
                </td> <!-- 상태 -->


                <td @click="movePage(`/item/${competition.Item.i_sku}`)" >{{ returnDateTime(competition.created_at) }}</td> <!-- 응모일시 -->

                <td v-if="competition.ci_status === 0 ">
                    <button @click="cancelRequest(competition,$event)" class="endDate">{{ $t('entry') }}{{ $t('cancel') }}
                    </button>
                </td>  <!-- 응모취소 -->
                <td  @click="movePage(`/item/${competition.Item.i_sku}`)"  v-else>-</td>
            </tr>
        </table>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
export default {
    name: "CompetitionListMyEntryLayout",
    mixins: [alertMixins, imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {default: () => {}},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, 'png')}`
            }
            return ''
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnPercentage(v, t) {
            if (t !== 0) {
                return parseFloat((v / t * 100).toFixed(2))
            } else {
                return 0
            }
        },
        returnDateTime(time) {
            let r_date = this.$moment(time).format('YYYY-MM-DD HH:mm')
            return r_date;
        },
        returnStatus(status) {
            switch (status) {
                case 0 : {
                    return this.$t('in_process2')
                }
                case 1 : {
                    return this.$t('awards')
                }
                case 2 : {
                    return this.$t('award_fail')
                }
                case 3 : {
                    return this.$t('award_cancel')
                }
                default : {
                    return ''
                }
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        cancelRequest(competition,e) {
            e.stopPropagation()
            let itemName = competition.Item.i_name
            let key = competition.ci_idx
            this.createConfirm({
                title: '',
                content: `<span class='subject'>${itemName}</span><br>${this.$t('my_cancel_confirm2')}<br> ${this.$t('my_cancel_confirm3')}`,
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('deleteCompetition',key)
                },
                cancel: () => {

                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
